import { sendLogin } from "../services/authServices"


const login = async (data:any) => {
    
    const callData = await sendLogin(data);

   
    return callData;

}

export {
    login
}