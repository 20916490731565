import { useState, useEffect, useContext } from "react";

import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import "../../styles/profile.css";
import { Avatar, Button, Drawer, Form, Input, Select } from "antd";
import { useCompanies } from "../../hooks/companiesHook";
import DataLabelsCompany from "../../components/datalabels/dataLabelsCompany";
import DataLabel from "../../components/datalabels/dataLabels";
import { avatar } from "../../environment";
import { postCompany, putCompany } from "../../services/companiesServices";
import { AntDesignOutlined, EditOutlined } from "@ant-design/icons";
import { IconHeader } from "../../components/icons/iconHeader";

const FormsCompanies = ({ close }: any) => {
  const { companies } = useCompanies();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [type, setType] = useState("none");
  const [form] = Form.useForm();
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [dataLabels, setDataLabels] = useState([]);
  const [newLabels, setNewLables] = useState<any>(null);
  const [image, setImage] = useState("");

  useEffect(() => {
    clean();
    if (platformState.dataUpdate) {
      setImage(platformState.dataUpdate.image);
      form.setFieldValue("name", platformState.dataUpdate.name);
      if (platformState.dataUpdate.father) {
        form.setFieldValue("type", "company");
        form.setFieldValue("project", platformState.dataUpdate.father);
        setDataLabels(platformState.dataUpdate["data_labels"]);
        setNewLables(platformState.dataUpdate["data_labels"]);
        //showChildrenDrawer()
      }
    }
  }, [platformState.openModal]);

  const action = async () => {
    if (platformState.dataUpdate) return await update();
    let fatherName;
    let { name, project } = form.getFieldsValue();
    if (project) {
      const objetoEncontrado = companies.find(
        (objeto: any) => objeto.uuid === project
      );
      fatherName = objetoEncontrado.name;
    }
    let env = {
      addres: "",
      name: name,
      father: project,
      fatherName: fatherName,
      image: image,
      data_labels: newLabels || dataLabels,
    };
    await postCompany(env);
    clean();
    await dispatch({
      type: types.test,
      payload: Math.random(),
    });
    return close();
  };

  const update = async () => {
    let fatherName;
    let { name, project } = form.getFieldsValue();
    if (project) {
      const objetoEncontrado = companies.find(
        (objeto: any) => objeto.uuid === project
      );
      fatherName = objetoEncontrado.name;
    }
    let env = {
      addres: "",
      name: name,
      father: project,
      fatherName: fatherName,
      image: image,
      data_labels: dataLabels,
    };

    await putCompany(env, platformState.dataUpdate?.uuid);
    clean();
    await dispatch({
      type: types.test,
      payload: Math.random(),
    });
    return close();
  };

  const clean = () => {
    form.resetFields();
    setType("none");
    setNewLables([]);
    return setDataLabels([]);
  };
  const handleChangeType = (value: string) => {
    setType(value);
    if (value === "project") {
      return showChildrenDrawer();
    }
    if (value === "company") {
      setNewLables([]);
      return setDataLabels([]);
    }
    onChildrenDrawerClose();
  };
  const handleChange = (value: string) => {
    cargarDataLabels(value);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const cargarDataLabels = (e: string) => {
    if (type === "company") {
      const index = companies.map((m: any) => m.uuid).indexOf(e);

      if (index > -1) {
        setDataLabels(companies[index]["data_labels"] || []);
        showChildrenDrawer();
        if (companies[index]["data_labels"].length > 0) {
          showChildrenDrawer();
        }
      }
    }
  };

  const cargarImagenTobase64 = async () => {
    let img: any = await document.getElementById("file-input");
    let archivo = img.files[0];
    const reader: any = new FileReader();

    reader.readAsDataURL(archivo);

    reader.onload = () => {
      setImage(reader.result);
    };
  };
  return (
    <>
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
        onFinish={action}
        style={{ textAlign: "center" }}
      >
        <Avatar
          style={{ marginBottom: 10 }}
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<IconHeader url={image} />}
        />
        <div className="image-upload" style={{ marginBottom: 10 }}>
          <label htmlFor="file-input" onClick={() => {}}>
            {platformState.language.profileEditar} &nbsp;{" "}
            <EditOutlined width="2rem" />
          </label>
          <input
            id="file-input"
            onChange={() => cargarImagenTobase64()}
            type="file"
          />
        </div>

        <Form.Item
          label={platformState.language.profileName}
          name="name"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={platformState.language.companyType}
          name="type"
          rules={[{ required: true }]}
        >
          <Select
            defaultValue="none"
            onChange={handleChangeType}
            options={[
              { value: "none", label: platformState.language.companyTypeThree },
              {
                value: "project",
                label: platformState.language.companyTypeTwo,
              },
              {
                value: "company",
                label: platformState.language.companyTypeOne,
              },
            ]}
          />
        </Form.Item>

        {form.getFieldValue("type") === "company" && (
          <Form.Item
            label={platformState.language.companyTypeTwo}
            name="project"
            rules={[{ required: true }]}
          >
            <Select
              defaultValue="none"
              onChange={handleChange}
              options={[
                {
                  value: "none",
                  label: platformState.language.companyTypeThree,
                },
                ...companies.map((e: any) => {
                  return {
                    value: e.uuid,
                    label: e.name,
                  };
                }),
              ]}
            />
          </Form.Item>
        )}
        {dataLabels.length > 0 && (
          <Form.Item label=" " style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="button"
              onClick={showChildrenDrawer}
            >
              campos adicionales
            </Button>
          </Form.Item>
        )}

        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {platformState.botonModalForm}
          </Button>
        </Form.Item>
      </Form>
      <Drawer
        title="Campos adicionales"
        width={420}
        closable={true}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
      >
        {form.getFieldValue("type") === "project" && (
          <DataLabel
            platformState={platformState}
            setDataLabels={setDataLabels}
            dataLabels={dataLabels}
          />
        )}
        {form.getFieldValue("type") === "company" && (
          <DataLabelsCompany
            setNewLables={setNewLables}
            newLabels={newLabels}
            dataLabels={dataLabels}
            setDataLabels={setDataLabels}
          />
        )}
      </Drawer>
    </>
  );
};

export default FormsCompanies;
