import { useContext, useEffect } from "react";

import { loadLanguaje } from "../hooks/loadLanguje";
import { PlatformContext } from "../context/platformContext";
import { useValidate } from "../hooks/protectRoutes";
import SideNav from "../pages/shared/Sidenav";
import { useSesion } from "../hooks/veirficateSesion";


const DashboardRouter = () => {
     const { platformState ,dispatch }: any = useContext(PlatformContext)
     const {selectempresa} = useValidate()
     const { users} = useSesion()
     useEffect(() =>{
       
          let idioma : string =  localStorage.getItem("idioma") ||  'es'
          let user : any = "" //JSON.parse(localStorage.getItem("session") )
          loadLanguaje(idioma,user, dispatch);
          
},[])
 return(
      <>{selectempresa}
         <SideNav />  
      
    </>)
}

export default DashboardRouter;