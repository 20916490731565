//export const api = "https://vp-sever.ue.r.appspot.com/api/v1/"

//export const api = "http://localhost:5000/api/v1/"
//export const api = "http://51.222.107.207:5000/api/v1/"

//export const api = "https://vikuaplatform.ue.r.appspot.com/api/v1/"
export const api = "https://jellyfish-app-7jjlo.ondigitalocean.app/api/v1/"
export const avatar = "https://storage.googleapis.com/vikua-styles/logos/logo_small_vikua.png"

export const pdfImage = "https://firebasestorage.googleapis.com/v0/b/images-1228d.appspot.com/o/pdfIcon.png?alt=media&token=7b4d9695-f71d-45ff-b7b9-6161c52b09ae"
    