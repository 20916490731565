import { api } from "../environment";


const postTracking = async(data: any) => {
   
    let token = localStorage.getItem('token') 
    let sessionTem = localStorage.getItem('session') || ""
    let {session} = JSON.parse(sessionTem)
    let options = {  
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}` ,
        'session': `${session}`
      },
      body: JSON.stringify(data)
    }
  
        try{
         
      
          const login = await fetch(api+"log-activity", options
          );
          const data = await login.json();
          if(data.error){
          
          }
         
          return data;
        }catch(e){
  
       
          return e;
        }
  } 

  export {
    postTracking
  }