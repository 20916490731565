import {  createContext, useReducer } from "react";
import { platformReducer } from "./platfomReducer"

const createContexts : any = createContext;
export const PlatformContext : any = createContexts()

const initialState : any = {
    language: {},
    ruta: "",
    shiny: "",
    token: "",
    dataProfile:{},
    test: 0,
    dataUpdate: {},
    title: "Crear usuario",
    skip: 0,
    limit: 10,
    search: "",
    company: "",
    botonModalForm: "Crear",
    openModal: false
}

export const PlatformProvider = ({ children } : any) => {

    const [platformState, dispatch ] = useReducer(platformReducer, initialState);

    return(
        <PlatformContext.Provider value={{
            platformState,
            dispatch
        }}>
            {children }
        </PlatformContext.Provider>
    )
}