import { useContext, useEffect, useState } from "react"

import { PlatformContext } from "../context/platformContext";
export const useSesion = () => {
  
    const { platformState, dispatch }: any = useContext(PlatformContext)
    const [users, setBoards]: any = useState(null)

   const  data = async() => {
    let datas = localStorage.getItem("session")
    if (!datas) return verifySesion()
   
    
    }
    useEffect(() =>{

            
                data()   
       
       
       
    },[dispatch] )

    return  { users }
}


const verifySesion = () => {
   
    localStorage.clear()

    return window.location.replace("/")
}

