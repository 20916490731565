import { useContext } from "react";

import { types } from "../types/types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider, Space, Typography } from "antd";
import { PlatformContext } from "../context/platformContext";

const Paginator = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);

  function nestPage() {
    if (platformState.limit < 10) return toast("Here is your toast.");
    let page = platformState.skip + 10;
    let limit = platformState.limit + 10;
    dispatch({
      type: types.skip,
      payload: page,
    });
    dispatch({
      type: types.limit,
      payload: limit,
    });
  }
  function backPage() {
    if (platformState.skip === 0) return toast("Here is your toast.");
    let page = platformState.skip - 10;
    let limit = platformState.limit - 10;
    dispatch({
      type: types.skip,
      payload: page,
    });
    dispatch({
      type: types.limit,
      payload: limit,
    });
  }
  return (
    <Space split={<Divider type="vertical" />}>
      <Typography.Link onClick={()=> backPage()}>
    
        <span aria-hidden="true">&laquo;</span>
      </Typography.Link>
      <Typography.Link onClick={()=> nestPage()}>
        <span aria-hidden="true">&raquo;</span>
      </Typography.Link>
    </Space>
  );
};

export default Paginator;
