import { Route, Routes } from "react-router-dom";
import "../styles/login.css"
import Login from "../pages/login/login";
import Register from "../pages/register/register";
import { PlatformContext } from "../context/platformContext";
import { useContext } from "react";
import { loadLanguaje } from "../hooks/loadLanguje";
import { Col, Flex, Row, Select } from 'antd';


const AuthRouter = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext)
  

  const cambioIdoma = (valor: string) =>{
    loadLanguaje(valor,platformState.dataProfile, dispatch);
    localStorage.setItem( "idioma",valor)
  }
  return (
    < >
      <Row>
        <Col xs={0}  lg={14}  id="auth-complement">

          <img id="logo_vikua-white" src="https://storage.googleapis.com/vikua-styles/logos/logo_negative_vikua.png" />
          <h3 id="title-one">{platformState.language.authTitleOne}</h3>
          <div id="title-two">
            <h3 id="title-two-text">{platformState.language.authTitleTwo}</h3>
            <h3 id="title-two-text">{platformState.language.authTitleThree}</h3>
          </div>

        </Col>
        <Col xs={24}  lg={10} >
          <Flex justify={"flex-end"} align={"flex-end"}>
          <Select id="languaje" 
          onChange={cambioIdoma} defaultValue={"es"}>
            <Select.Option value={"es"} >Es</Select.Option>
            <Select.Option value={"en"}>En</Select.Option>
          </Select>
          </Flex>
   


           <Login />
         
        </Col>
      </Row>
    </>)
}

export default AuthRouter;