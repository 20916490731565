import { useContext, useEffect, useState } from "react";
import { getBoards } from "../services/boardsServices";
import { PlatformContext } from "../context/platformContext";

export const useBoards = () => {
    const [boards, setBoards]: any = useState(null)
    const { platformState, dispatch }: any = useContext(PlatformContext)
    async function data() {
        if(localStorage.getItem("session")){
            const dato: any = await getBoards()

            if(!dato || dato.message === 'Unauthorized'){
                
              return localStorage.clear();
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    },[platformState.openModal] )

    return  { boards }
}

