import React from 'react';
import { Card } from 'antd';
import {
    EditOutlined
  } from "@ant-design/icons";
import "../../styles/profile.css"
import { putAvatarProfile } from '../../services/usersServices';

const { Meta } = Card;

const CardProfile = ({user, labels, limpiar}: any) => {

  const cargarImagenTobase64 = async () => {
    let img: any = await document.getElementById('file-input');
    let archivo = img.files[0];
    const reader = new FileReader()

    reader.readAsDataURL(archivo)

    reader.onload = () => {

      //  setAvatar(reader.result)
      update(reader.result)
    }

}
const update = async(avatar: any) => {
    

  let env = {
    image: avatar,
    
}
   const resp = await putAvatarProfile(env,user.uuid)
    return limpiar(resp)
}

 return(
  <Card
    hoverable

    cover={<img alt="example" src={user.image} style={{ maxWidth: 240, height:240, marginInline: "auto", borderRadius: "50%", marginTop: 10}} />}
    extra={<div     className="image-upload"><label  htmlFor="file-input" onClick={()=>{}} >{labels.profileEditar} &nbsp; <EditOutlined width="2rem" /></label>  <input id="file-input" onChange={() => cargarImagenTobase64()} type="file" /></div>}
  >
    <Meta title={user.area}  style={{ textAlign: "center"}} />
  </Card>
);
}
export default CardProfile;