import CardBoard from "./card";
import { useBoards } from "../../hooks/boardsHook";

import { useRolesForUser } from "../../hooks/rolesForUserHook";
import { Col, Row } from "antd";
import Spinner from "../../components/spinner";

const ListBoards = () => {
  const { boards }: any = useBoards();
  const { rolesUser } = useRolesForUser();

  return (
    <>
      <Row
        gutter={{ xs: 6, sm: 12, md: 24, lg: 32 }}
        style={{
          justifyContent: rolesUser?.length > 2 ? "center" : "flex-start",
        }}
      >
        {!boards ? (
          <Spinner />
        ) : (
          <>
            {boards?.map((e: any) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  key={e.uuid}
                  style={{ marginTop: "1rem" }}
                >
                  <CardBoard data={e} role={rolesUser?.length} />
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </>
  );
};

export default ListBoards;

//$2b$10$RzUDaeJZK6Z4HrtLj0oNguQ3aFqIlmu9H0OeBTmCvjfZz9Fq33OG2
