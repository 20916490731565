import { useContext, useEffect, useState } from "react";
import "../../styles/login.css";
import { PlatformContext } from "../../context/platformContext";
import { loadLanguaje } from "../../hooks/loadLanguje";
import { useNavigate } from "react-router-dom";
import { login } from "../../hooks/login";
import { types } from "../../types/types";
import { confirmPassword, error } from "../../services/alertsServices";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Typography } from "antd";
import { PasswordProps } from "antd/es/input";

type FieldType = {
  email?: string;
  password?: PasswordProps;
};

const { Title } = Typography;


const Login = () => {
  let history: any = useNavigate();

  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadLanguaje("es", {}, dispatch);
  }, []);

  const sendLogin = async () => {

    let {email, password} = form.getFieldsValue()
    
    let env = {
      email: email,
      password: password,
    };

    const send: any = await login(env);

    if (send.message) {
      console.log(send.message)
      return error(send.message);
    }

    localStorage.setItem("token", send.token);

    // Agrega los campos de compania a objeto, omitiendo el campo image.

    let save = {
      idU: send.uuid,
      ...send,
    };
    localStorage.setItem("session", JSON.stringify(save));
    dispatch({
      type: types.token,
      payload: send.token,
    });
    history(`dashboard/${platformState.language.sidebarMisTableros}`, {
      replace: true,
    });
 
  };

  return (
    <>
      <Form
        id="formulario"
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={sendLogin}
        style={{  textAlign:"center" }}
        labelAlign={'left'}
      >
        <Title level={4} >
          {platformState.language.loginTitleForm}
        </Title>
        <Form.Item<FieldType>
          label={platformState.language.loginEmail}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label={platformState.language.loginPassword}
          name="password"
          rules={[
            {
              required: true,
              
              message: "Please input your email!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button type="primary" htmlType="submit">
            {platformState.language.loginButtonSendLongin}
          </Button>
        </Form.Item>
        <a className="recuperatePassword" onClick={() => confirmPassword()}>
            {platformState.language.loginRecuperatePassword}
          </a>
      </Form>


    </>
  );
};

export default Login;
