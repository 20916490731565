
import {api} from '../environment';


const sendLogin = async(data: any) => {

    let options =   {
        method: "POST",
        body: JSON.stringify(data),
        headers: {"Content-type": "application/json; charset=UTF-8"}
      }

      try{

        const login = await fetch(api+"auth", options);
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 

export {
    sendLogin
}