import { useContext, useEffect, useState } from "react";
import { getForUser, getRoles } from "../services/rolesServices";
import { PlatformContext } from "../context/platformContext";





export const useRolesForUser = () => {
    const { platformState }: any = useContext(PlatformContext);
    const [rolesUser, setBoards]: any = useState([])

    async function data() {

        const data = await getForUser()
        if(data?.statusCode === 401){
          return  setBoards([])
        }
        
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    },[platformState.ruta] )

    return  { rolesUser }
}

