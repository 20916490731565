import Swal from 'sweetalert2'
import { deleteUsers } from './usersServices'
import { deleteInsing } from './insingServices'
import { sendRecuperate } from './recuperatepassword'
import { deleteCompany } from './companiesServices'
import { types } from '../types/types'

const success = (text :string) => {
    Swal.fire({
        title: '',
        text: text,
        icon: 'success'
      })
}

const error = (text :string)  => {
    Swal.fire({
        title: 'Error!',
        text: text,
        icon: 'error'
      })
}
const warning = (text :string)  => {
  Swal.fire({
      title: 'Alto!',
      text: text,
      icon: 'warning'
    })
}
const confirm = (text :any, dispatch: any, type: string)  => {
    Swal.fire({
        title:  `Esta seguro que desea borrar este ${type}?`,
        showDenyButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'No borrar',
        denyButtonText: `Borrar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
         
        } else if (result.isDenied) {
          if(type === "insing"){
            deleteInsing(text, dispatch)
          }else if(type === "users"){ 
            deleteUsers(text, dispatch)
          }else if(type === "companies"){
            deleteCompany(text, dispatch)
          }
         
        }
      })
}

const confirmPassword = ()  => {
  Swal.fire({
    title: 'Enter your email',
    input: 'email',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'recuperate',
    showLoaderOnConfirm: true,
    preConfirm: (login) => {
      sendRecuperate({email:login})
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    success("Revise su correo electronico")
  })
}

const selectCompany = (array: any, companies: any,dispatch: any, navigate: any) =>{

  Swal.fire({
    title: 'Seleccione la empresa para ver el tablero',
    input: 'select',
    inputOptions: array,
    inputPlaceholder: 'Seleccion empresa',
    inputValidator: (value) => {
      dispatch({
        type: types.company,
        payload: companies[value]
      })
      return navigate("/view")
    }
  })
}
export {
    success, error, confirm, confirmPassword, warning, selectCompany
}