import { useEffect, useState } from "react";
import { getRoles } from "../services/rolesServices";





export const useRoles = () => {
    const [roles, setBoards]: any = useState([])

    async function data() {

        const data = await getRoles()
        if(data?.statusCode === 401){
          return  setBoards([])
        }
   
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    },[] )

    return  { roles }
}

