import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



export const useValidate = () => {
    const [selectempresa, setEmpresa] = useState(false)
    const history : any = useNavigate();
    useEffect(() =>{
    
        const intervalId = setInterval(() => {
            if(!localStorage.getItem("token")){
                setEmpresa(true)
                return history('auth',{
                    replace: true
                })
            }
          }, 1000);
          if(!selectempresa) return () => clearInterval(intervalId);
          
    
    })

    return  { selectempresa }
}

