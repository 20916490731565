import { useContext, useEffect, useState } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";
import { useUsers } from "../../hooks/usersHook";
import { useRoles } from "../../hooks/rolessHook";
import { types } from "../../types/types";
import { confirm } from "../../services/alertsServices";
import { avatar } from "../../environment";
import { useRolesForUser } from "../../hooks/rolesForUserHook";

import { TableCustom } from "../../components/table";
import { Button, Flex, Tag } from "antd";
import Spinner from "../../components/spinner";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { rolesUser } = useRolesForUser();
  const { users }: any = useUsers();
  const { roles }: any = useRoles();
  const [idBorrado, setIdBorrado] = useState("");
  const [rol, setRol] = useState([]);
  const columns = [
    {
      title: platformState.language.tableName,
      dataIndex: "name",
      key: "name",
    },
    {
      title: platformState.language.tableEmail,
      dataIndex: "email",
      key: "email",
    },
    {
      title: platformState.language.tableArea,
      dataIndex: "area",
      key: "area",
    },
    {
      title: platformState.language.tableRole,
      dataIndex: "role",
      key: "role",
      render: (role: string) => {
        return <>{asigrole(role)}</>;
      },
    },
    {
      title: platformState.language.tableFecha,
      dataIndex: "createAt",
      key: "createAt",
      render: (createAt: string) => {
        return <Tag key={createAt}>{formatearFechaCreacion(createAt)}</Tag>;
      },
    },
    {
      title: platformState.language.tableAccesos,
      dataIndex: "lastAccess",
      key: "lastAccess",
      render: (lastAccess: string) => {
        return (
          <Tag key={lastAccess}>{formatearFechaCreacion(lastAccess)}</Tag>
        );
      },
    },
    {
      title: platformState.language.tableEstado,
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => {
        return (
          <>
            {status ? (
              <Tag color="green">{platformState.language.tableActive}</Tag>
            ) : (
              <Tag color="red">{platformState.language.tableInactive}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: platformState.language.tableAcciones,
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => {
        return (
          <Flex gap="small" wrap>
            <Button
              onClick={() => {
                uploadData(uuid);
              }}
            >
              <EditOutlined />
            </Button>
            
            <Button
              onClick={() => {
                borrar(uuid);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Flex>
        );
    },
  }
  ];

  useEffect(() => {}, [platformState.test]);

  function formatearFechaCreacion(data: any) {
    let fecha = new Date(data);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se suma 1
    const ano = fecha.getFullYear();

    // Formatear en un string con el formato deseado
    return `${dia < 10 ? "0" : ""}${dia}/${mes < 10 ? "0" : ""}${mes}/${ano}`;
  }

  function calcularDiferenciaAutomatica(data: any) {
    let fechaInicial: any = new Date(data);
    const fechaActual: any = new Date();
    const tiempoTranscurrido = fechaActual - fechaInicial; // Diferencia en milisegundos
    const aniosTranscurridos = tiempoTranscurrido / (1000 * 60 * 60 * 24 * 365);

    if (aniosTranscurridos < 1) {
      const mesesTranscurridos = aniosTranscurridos * 12;
      if (mesesTranscurridos < 1) {
        const semanasTranscurridas =
          tiempoTranscurrido / (1000 * 60 * 60 * 24 * 7);
        if (semanasTranscurridas < 1) {
          const diasTranscurridos = tiempoTranscurrido / (1000 * 60 * 60 * 24);

          return `Hace ${Math.floor(diasTranscurridos)} días`;
        } else {
          return `Hace ${Math.floor(semanasTranscurridas)} semanas`;
        }
      } else {
        return `Hace ${Math.floor(mesesTranscurridos)} meses`;
      }
    } else {
      return `Hace ${Math.floor(aniosTranscurridos)} años`;
    }
  }
  const asigrole = (id: string) => {
    for (let i in roles) {
      if (id === roles[i].uuid) {
        return roles[i].name;
      }
    }
  };
  function uploadData(item: any) {
    const data = users.find((m: any) => m.uuid === item)

    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.sidebarUsers,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: data,
    });
  }
  const borrar = async (id: string) => {
    return confirm(id, dispatch, "users");
  };
  return (
    <>{ !users
      ? <Spinner />
      :<TableCustom columns={columns} data={users} />

    }

      
    </>
  );
};

export default Tables;
