import { useContext, useEffect, useState } from "react";
import {  Route, Routes, useNavigate } from "react-router-dom";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import type { MenuProps } from "antd";
//iconos
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import { useRolesForUser } from "../../hooks/rolesForUserHook";
import { Button, Col, Menu, Row } from "antd";
import { Flex, Layout } from "antd";
import ModalForms from "../../components/modalForms";
import {
  IconAdministrato,
  IconDataPlayer,
  IconDataVikua,
  IconEmpresas,
  IconExit,
  IconInsights,
  IconMiData,
  IconMistableros,
  IconTablerosVikua,
  IconUsers,
} from "../../components/icons/iconMistableros";
import "../../styles/general.css";
import HeaderCustom from "../../components/headerCustom";
import Boards from "../boards/boards";
import Companies from "../companies/companies";
import Users from "../users/users";
import Insing from "../insitg/insing";
import Profile from "../profile/profile";
import Construccion from "./construction";
const { Header, Footer, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const SideNav = () => {
  let history: any = useNavigate();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const data: any = JSON.parse(localStorage.getItem("session") || "{}");
  const { rolesUser } = useRolesForUser();

  const items: MenuItem[] = [
    {
      key: platformState.language.sidebarMisTableros,
      icon: <IconMistableros />,
      label: platformState.language.sidebarMisTableros,
    },
    {
      key: platformState.language.sidebarTablerosVikua,
      icon: <IconTablerosVikua />,
      label: platformState.language.sidebarTablerosVikua,
    },
    {
      key: platformState.language.sidebarInsights,
      icon: <IconInsights />,
      label: platformState.language.sidebarInsights,
    },
    {
      key: platformState.language.sidebarDataPlayer,
      icon: <IconDataPlayer />,
      label: platformState.language.sidebarDataPlayer,
    },
    {
      key: platformState.language.sidebarMydata,
      icon: <IconMiData />,
      label: platformState.language.sidebarMydata,
    },
    {
      key: platformState.language.sidebarDatavikua,
      icon: <IconDataVikua />,
      label: platformState.language.sidebarDatavikua,
    },
    {
      key: platformState.language.sidebarUsers,
      icon: <IconUsers />,
      label: platformState.language.sidebarUsers,
    },
    {
      key: platformState.language.sidebarEmpresa,
      icon: <IconEmpresas />,
      label: platformState.language.sidebarEmpresa,
    },
    {
      key: platformState.language.sidebarAdministrator,
      icon: <IconAdministrato />,
      label: platformState.language.sidebarAdministrator,
    },
    {
      key: "auth",
      icon: <IconExit />,
      label: platformState.language.sidebarCloseSession,
    },
  ];

  useEffect(() => {}, []);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const loadTitle = (ruta: string) => {
    dispatch({
      type: types.ruta,
      payload: ruta,
    });
  };

  const navigator = (path: string) => {
    if(path === "auth" ){
      localStorage.clear();

      return history("/", {
        replace: true,
      });
    }
    loadTitle(path)
     return history(`${path}`)

  };

  return (
    <Flex style={{ height: "100vh" }}>
      <Layout>
        <Sider
          collapsed={collapsed}
          theme="dark"
          onCollapse={(value) => setCollapsed(value)}
          style={{ textAlign: "center" }}
        >
          <div style={{ width: "100%", display: "block", textAlign: "center" }}>
            <Button
              type="primary"
              onClick={toggleCollapsed}
              style={{ marginBottom: 16, backgroundColor: "#001529" }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </div>

          <img
            id="imglogo"
            src="https://storage.googleapis.com/vikua-styles/logos/logo_small_vikua.png"
          />

          <Menu
            defaultSelectedKeys={["1"]}
        
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
            onClick={(e:any)=>{navigator(e.key)}}
            style={{
              textAlign: "start",
              height: "auto",
            }}
          />
        </Sider>
        <Layout style={{ height: "100vh", maxHeight: "100vh", overflowY: "auto", overflowX: "hidden", backgroundColor: "#eeeeee" }}>
          <HeaderCustom />

          <Content style={{ marginTop: "3vh" , padding: "2vh" }}>
            <Routes>
              <Route path={platformState.language.sidebarEmpresa} element={<Companies />} />
              <Route path={platformState.language.sidebarTablerosVikua} element={<Construccion />} />
              <Route path={platformState.language.sidebarDataPlayer} element={<Construccion />} />
              <Route path={platformState.language.sidebarMydata} element={<Construccion />} />
              <Route path={platformState.language.sidebarDatavikua} element={<Construccion />} />
              <Route path={platformState.language.sidebarAdministrator} element={<Construccion />} />

              <Route path={platformState.language.sidebarUsers} element={<Users />} />
              <Route path={platformState.language.sidebarMisTableros} element={<Boards />} />
              <Route path={platformState.language.sidebarInsights} element={<Insing />} />
              <Route path={platformState.language.profile} element={<Profile />} />
              <Route path={platformState.language.sidebarMisTableros} element={<Boards />} />
              <Route path="/" element={<Boards />} />
             
            </Routes>
            {rolesUser?.length > 2 && <ModalForms />}
            
          </Content>
        </Layout>
      </Layout>
    </Flex>
  );
};

export default SideNav;
