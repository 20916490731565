import { useContext, useEffect, useState } from "react";
import "../../styles/mistableros.css"
import { PlatformContext } from "../../context/platformContext";
import { getCompanie } from "../../services/companiesServices";
const ShinyView = () => {
  const { platformState }: any = useContext(PlatformContext)
  const [companyUser, setCompanyUser]: any = useState(null)

  useEffect(() => {


   


ciclo()
  }, []);

  const ciclo = async () => {
 
       if(platformState.company){
        let iframeElement: any = document.getElementById('view');

        iframeElement.addEventListener('load', miFuncion);
       }
    
    
  }
   function miFuncion() {
  
    let iframe: any = document.getElementById('view');
    let emial: any = JSON.parse(localStorage.getItem('session') || "")
    //alert(JSON.stringify({email: emial.email,"permission_analytics":emial["permission_analytics"], ...platformState.company}))
    iframe.contentWindow.postMessage(JSON.stringify({email: emial.email,"permission_analytics":emial["permission_analytics"], ...platformState.company}), '*');

  }
  return (<>
    {platformState.company
      ? <iframe id="view" src={platformState.shiny} ></iframe>
      : <></>
    }


  </>)
}

export default ShinyView;