import { useContext, useEffect } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";
import { confirm } from "../../services/alertsServices";

import { useInsing } from "../../hooks/insingHook";
import { Button, Flex, Tag } from "antd";
import Spinner from "../../components/spinner";
import { TableCustom } from "../../components/table";
import {
  DeleteOutlined,
  EditOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { types } from "../../types/types";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { insing }: any = useInsing();

  const columns = [
    {
      title: "#",
      dataIndex: "image",
      key: "image",
      render: (image: string) => {
        return (
          <a href={image}>
            <CloudDownloadOutlined />
          </a>
        );
      },
    },
    {
      title: platformState.language.insingTitle,
      dataIndex: "title",
      key: "title",
    },
    {
      title: platformState.language.insingDescription,
      dataIndex: "description",
      key: "description",
    },
    {
      title: platformState.language.tableFecha,
      dataIndex: "createAt",
      key: "createAt",
      render: (createAt: string) => {
        return <Tag key={createAt}>{formatearFechaCreacion(createAt)}</Tag>;
      },
    },
    {
      title: platformState.language.tableAcciones,
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => {
        return (
          <Flex gap="small" wrap>
             <Button
              onClick={() => {
                uploadData(uuid);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => {
                borrar(uuid);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Flex>
        );
      },
    },
  ];
  useEffect(() => {}, [platformState.test]);

  const borrar = async (id: string) => {
    return confirm(id, dispatch, "insing");
  };
  
  function uploadData(item: any) {
    const data = insing.find((m: any) => m.uuid === item)

    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.modalCompaniesTitleEdit,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: data,
    });
  }
  function formatearFechaCreacion(data: any) {
    let fecha = new Date(data);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se suma 1
    const ano = fecha.getFullYear();

    // Formatear en un string con el formato deseado
    return `${dia < 10 ? "0" : ""}${dia}/${mes < 10 ? "0" : ""}${mes}/${ano}`;
  }

  return (
    <>
      {!insing ? <Spinner /> : <TableCustom columns={columns} data={insing} />}
    </>
  );
};

export default Tables;
