import { useContext, useEffect, useState } from "react";
import { getCompanies, getCompaniesAll } from "../services/companiesServices";



import { PlatformContext } from "../context/platformContext";


export const useCompanies = () => {
    const { platformState }: any = useContext(PlatformContext)
    const [companies, setBoards]: any = useState([])

    async function data() {

        const data = await getCompanies()
        if(data?.statusCode === 401){
            
          return  setBoards([])
        }
        
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    },[PlatformContext.test, platformState.dataUpdate] )

    return  { companies }
}

export const useCompaniesAll = () => {
    const { platformState }: any = useContext(PlatformContext)
    const [companies, setBoards]: any = useState([])

    async function data() {
        setBoards([])    
       
  
        const data = await getCompaniesAll(platformState.skip, platformState.limit, platformState.search);
        
        if(data?.statusCode === 401){
           
          return  setBoards([])
        }
        
        return setBoards(data)
    }
    useEffect(() =>{
        
        data()
       
    },[platformState.test, platformState.skip, platformState.search] )

    return  { companies }
}