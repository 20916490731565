
import { api } from '../environment';
import { Tracking } from '../interfaces/tracking';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""

}

const getBoards = async () => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') 
  if (!sessionTem) return
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Get Boards"
    tracking.description = "Get all boards"
    tracking.uuidSesion = session
    await postTracking(tracking)
    const login = await fetch(api + "boards", options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}


const postBoards = async (data: any) => {

  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') 
  if (!sessionTem) return
  let { session } = JSON.parse(sessionTem) 
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Create Boards"
    tracking.description = "Create a new board"
    tracking.uuidSesion = session
    await postTracking(tracking)
    const login = await fetch(api + "boards", options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const updateBoards = async (data: any, id: string) => {

  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') 
  if (!sessionTem) return
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {

    tracking.activity = "Update Boards"
    tracking.description = "Update a board"
    tracking.uuidSesion = session
    await postTracking(tracking)
    const login = await fetch(api + "boards/" + id, options
    );
    const data= await login.json();
    return data;
  } catch (e) {
    return e;
  }
}



export {
  getBoards, postBoards, updateBoards
}