
import {api} from '../environment';
import { Tracking } from '../interfaces/tracking';
import { types } from '../types/types';
import { error, success } from './alertsServices';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""
}

const getCompanies = async() => {
  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`      
    }
  }

      try{
       tracking.activity = "Get Companies"
        tracking.description = "Get all companies"
        tracking.uuidSesion = session
        await postTracking(tracking)
    
        const login = await fetch(api+"companies/All", options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 
const getCompaniesAll = async(skip: any, limit: any, search: string) => {
  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`      
    }
  }

      try{
       tracking.activity = "Get Companies"
        tracking.description = "Get all companies"
        tracking.uuidSesion = session
        await postTracking(tracking)
    
        const login = await fetch(api+"companies"+`?limit=${10}&offset=${skip}&search=${search}`, options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 
const getSubCompanies = async(id:string) => {
  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    }
  }

      try{
       tracking.activity = "Get SubCompanies"
        tracking.description = "Get all subcompanies"
        tracking.uuidSesion = session
        await postTracking(tracking)
    
        const login = await fetch(api+"companies/subcompanies/"+id, options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 

const getCompanie = async(id:any, tok = null) => {
  let token =  tok || localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`
    }
  }

      try{
       
        tracking.activity = "Get Companie"
        tracking.description = "Get a companie"
        tracking.uuidSesion = session
        await postTracking(tracking)
        const login = await fetch(api+"companies/"+id, options
        );
        
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
} 

const getBoardsForId = async(id : string) => {

  let token = localStorage.getItem('token') 
  let sessionTem = localStorage.getItem('session') || ""
  let {session} = JSON.parse(sessionTem)
  let options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}` ,
      'session': `${session}`      
    }
  }

      try{
       tracking.activity = "Get Companies"
        tracking.description = "Get all companies"
        tracking.uuidSesion = session
        await postTracking(tracking)
    
        const login = await fetch(api+"boards/"+id, options
        );
        const data = await login.json();
        return data;
      }catch(e){
        return e;
      }
}

const deleteCompany = async (id: string, dispatch: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Delete Insing"
    tracking.description = "Delete a insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "companies/" + id, options
    );
    const data = await login.json();
    if (data.error) {

    } else {
      dispatch({
        type: types.test,
        payload: Math.random()
      })
    }

    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}

const postCompany = async (data: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Create Company"
    tracking.description = "Create a new company"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "companies", options
    );
    const data = await login.json();
    if (data.error) {
      error("No se creo la empresa")
    } else {
      success("Empresa creada con exito")
    }

    return data;
  } catch (e) {

    error("No se creo la empresa")
    return e;
  }
}

const putCompany = async (data: any, id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Update company"
    tracking.description = "Update a company"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "companies/" + id, options
    );
    const data = await login.json();
    if (data.error) {
      error("No se actualizo la empresa")
    } else {
      success("Empresa actualizada con exito")
    }

    return data;
  } catch (e) {

    error("No se actualizo la empresa")
    return e;
  }
}

export {
    getCompanies, getSubCompanies, getCompanie,  getCompaniesAll, getBoardsForId, deleteCompany, postCompany, putCompany
}